<template>
  <div class="flex flex-col w-full h-full p-5">
    <div class="page-title">
      <h2>
        {{ content.title }}
      </h2>
    </div>
    <span v-if="pdf">
      <div
        class="font-normal flex"
        v-if="showInformation"
      >
        <div class="mr-9 pl-5 py-1">
          <p><strong>Name</strong></p>
        </div>
        <p class="font-normal  py-1">{{ mainCandidate.name }}</p>
        <div class="mr-9 pl-5 py-1">
          <p><strong>Age</strong></p>
        </div>
        <p class="font-normal  py-1">{{ mainCandidate.current_age }}</p>
        <div class="mr-9 pl-5 py-1">
          <p><strong>Education</strong></p>
        </div>
        <p class="font-normal  py-1">{{ education[mainCandidate.level_of_education] }}</p>
        <div class="mr-9 pl-5 py-1">
          <p><strong>Current Position</strong></p>
        </div>
        <p class="font-normal  py-1">{{ jobPositions[mainCandidate.title] }}, {{ jobLevels[mainCandidate.level] }}</p>
      </div>

    </span>
    <div>
      <span
        class=" flex justify-end"
        v-if="!pdf"
      >
        <show-details-btn
          class="self-center pb-3"
          v-model="showInformation"
          :main="true"
        /></span>
    </div>
    <span v-if="!pdf && showInformation">
      <div class="flex flex-row justify-between">
        <div
          style="width: 380px"
          class="mr-9 pl-5 py-1"
        >
          <p><strong>Name</strong></p>
        </div>
        <div
          style="width: 550px"
          class="font-normal ml-10 pl-1 min-w-0"
        >

          <p>{{ mainCandidate.name }}</p>
        </div>
        <div
          style="width: 700px"
          class="ml-10"
        />
      </div>
      <div class="flex flex-row justify-between">
        <div
          style="width: 380px"
          class="mr-9 pl-5 py-1"
        >
          <p><strong>Age</strong></p>
        </div>
        <div
          style="width: 550px"
          class="font-normal ml-10 pl-1 min-w-0"
        >

          <p>{{ mainCandidate.current_age }}</p>
        </div>
        <div
          style="width: 700px"
          class="ml-10"
        />
      </div>
      <div class="flex flex-row justify-between">
        <div
          style="width: 380px"
          class="mr-9 pl-5 py-1"
        >
          <p><strong>Education</strong></p>
        </div>
        <div
          style="width: 550px"
          class="font-normal ml-10 pl-1 min-w-0"
        >

          <p>{{ education[mainCandidate.level_of_education] }}</p>
        </div>
        <div
          style="width: 700px"
          class="ml-10"
        />
      </div>
      <div class="flex flex-row justify-between">
        <div
          style="width: 380px"
          class="mr-9 pl-5 py-1"
        >
          <p><strong>Current Position</strong></p>
        </div>
        <div
          style="width: 550px"
          class="font-normal ml-10 pl-1 min-w-0"
        >

          <p>{{ jobPositions[mainCandidate.title] }}, {{ jobLevels[mainCandidate.level] }}</p>
        </div>
        <div
          style="width: 700px"
          class="ml-10 "
        />
      </div>
    </span>
    <div class="flex flex-row justify-between">
      <div
        style="width: 380px"
        class="mr-9 pl-5 py-1"
      >
        <p><strong>State during test session</strong></p>
      </div>
      <div
        style="width: 550px"
        class="ml-9"
      >
        <state-summary :state="stateCheck()" />
      </div>
      <div
        style="width: 700px"
        class="ml-11"
      >
        <p class="font-normal">
          <span
            class=""
            v-if="mainCandidate.mood_level >= 6"
          >High mood</span>
          <span v-else>Low mood</span>

          <span
            class=""
            v-if="mainCandidate.motivational_level >= 6"
          >, highly motivated</span>
          <span v-else>, low motivation</span>

          <span
            class=""
            v-if="mainCandidate.sleep_duration <= 270"
          >, low sleep</span>
          <span v-else-if="mainCandidate.sleep_duration >= 300 && mainCandidate.sleep_duration <= 390">, adequate sleep</span>
          <span v-else>, good sleep</span>
          <span
            class=""
            v-if="mainCandidate.tiredness_level <= 6"
          >, not tired</span>
          <span v-else>, tired</span>
          <span
            v-if="mainCandidate.undisturbed_environment >= 0.5"
          >, undisturbed environment.</span>
          <span v-else>, disturbed environment. </span>
          <span class=" ">
            Red and yellow indicate a state that may affect test score.
          </span>
        </p>
      </div>
    </div>

    <div
      style="background: #46634F; border-radius: 10px 10px 0px 0px; margin-bottom: -1px;"
      class="flex flex-row justify-between m-0 text-white font-bold"
    >
      <div
        style="width: 380px;"
        class="mt-3 mr-10 pl-6 py-1 h-full"
      >
        <p>
          AREA
        </p>
      </div>
      <div class="mt-3 py-1 ml-10 min-w-0">
        <div class="flex">
          <p style="width: 550px">
            OUTCOME
          </p>
          <p
            style="width: 700px"
            class="ml-10"
          >
            DESCRIPTION
          </p>
        </div>
      </div>
    </div>

    <div
      style=" margin-bottom: -1px;"
      class="flex flex-row justify-between m-0"
    >
      <div
        style="width: 380px; background: #46634F;"
        class="mr-10 pl-6 pt-10 pb-1 text-white h-full"
      >
        <p>
          <strong>Indicated Strengths</strong>
        </p>
      </div>
      <div class="font-normal pt-10 pb-1 ml-10 min-w-0">
        <span
          class="capitalizeFirstLetter"
          v-for="strength in strongest"
          :key="'strength: ' + strength"
        >
          <div class="flex">
            <p
              style="width: 550px"
            >{{ sortedData[strength].label }}</p>
            <p
              style="width: 700px"
              class="ml-10"
            >
              {{ CognitiveProfileStrengths.summaries[sortedData[strength].label].charAt(0).toUpperCase() + CognitiveProfileStrengths.summaries[sortedData[strength].label].slice(1) }}.
            </p>
          </div>
        </span>
      </div>
    </div>

    <div
      v-if="development.length > 0"
      class="flex flex-row justify-between m-0"
    >
      <div
        style="width: 380px; background: #46634F; border: none;"
        class=" mr-10 pl-6 py-1 text-white h-full"
      >
        <p>
          <strong>Indicated Challenges</strong>
        </p>
      </div>
      <div class="font-normal py-1 ml-10 min-w-0">
        <span
          v-for="dev in development"
          :key="'dev: ' + dev"
        >
          <div class="flex">
            <p style="width: 550px">{{ sortedData[dev].label }}</p>
            <p
              style="width: 700px"
              class="ml-10"
            >
              {{ CognitiveProfileStrengths.summaries[sortedData[dev].label].charAt(0).toUpperCase() + CognitiveProfileStrengths.summaries[sortedData[dev].label].slice(1) }}.
            </p>

          </div>
        </span>
      </div>
    </div>

    <div
      class="flex flex-row justify-between m-0"
      style="margin-top: -2px"
    >
      <div
        style="width: 380px; background: #46634F; border: none;"
        class=" mr-10 pl-6 py-1 pb-10 pt-1 text-white h-full min-w-0"
      >
        <p>
          <strong>Total Average Cognitive Capacity</strong>
        </p>
      </div>
      <div class="font-normal py-1 ml-10 min-w-0">
        <div class="flex">
          <p style="width: 550px">
            {{ parseFloat(mainCandidate.total_capacity_score).toFixed(1) }}
          </p>
          <p
            style="width: 700px"
            class="ml-10"
          />
        </div>
      </div>
    </div>

    <div
      class="flex flex-row justify-between m-0"
      style="margin-top: -3px;"
    >
      <div
        style="width: 380px; background: #46634F;"
        class=" mr-10 pl-6 py-1 text-white h-full min-w-0"
      >
        <p>
          <strong>Cognitive Capacity</strong>
          <ul v-if="chartData">
            <li
              v-for="measure in sortedData"
              :key="'measure: ' + measure.label"
              class="my-2 font-normal"
            >
              <div
                v-if="[measure.stanine] != 0"
                class="flex"
              >
                <div class="dropdown dropdown-hover">
                  <div tabindex="0">
                    <div
                      class="font-normal"
                    >
                      {{ measure.label }}
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </p>
      </div>
      <div class="font-normal py-1 ml-10 min-w-0">
        <div class="flex">
          <div
            style="width: 550px"
            class="flex"
          >
            <ul
              v-if="chartData"
              style="padding-top: 20px; padding-right: 10px"
            >
              <li
                v-for="measure in sortedData"
                :key="'label: ' + measure.label"
                style="margin-top: 8px"
              >
                <div class="sm:flex lg:flex">
                  <div
                    v-if="[measure.stanine] != 0"
                    class="sm:flex lg:flex"
                  >
                    <bar-chart
                      :options="smallBarChartOptions"
                      :height="21"
                      :chart-data="{ labels: [measure.label], datasets: [{ label: measure.label, data: [measure.stanine], backgroundColor: measure.color }] }"
                    />
                  </div>
                </div>
              </li>
            </ul>
            <p class=" pr-5">
              <strong>Score</strong>
              <ul v-if="chartData">
                <li
                  v-for="measure in sortedData"
                  :key="'stanine: ' + measure.label"
                  style=""
                >
                  <div v-if="[measure.stanine] != 0">
                    <div class="dropdown dropdown-hover">
                      <div tabindex="0">
                        <div
                          style=" line-height: 29px;"
                          class="font-normal"
                        >
                          {{ measure.stanine }}
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </p><p>
              <strong>Awareness</strong>
              <ul v-if="chartData">
                <li
                  v-for="measure in sortedData"
                  :key="'comparativePerformanceLevel: ' + measure.label"
                >
                  <div>
                    <div class="dropdown dropdown-hover">
                      <div tabindex="0">
                        <div
                          style=" line-height: 29px;"
                          class="font-normal"
                        >
                          {{ measure.comparativePerformanceLevel }}
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </p>
          </div>

          <p
            style="width: 700px"
            class="ml-10"
          >
            <strong>Score</strong> is the candidate’s cognitive capacity score.
            <strong>Awareness</strong> is the candidate’s estimation of the own performance.
            Mean score is 5. The score indicates allround potential. The measure should be related to both awareness and actual use.
          </p>
        </div>
      </div>
    </div>

    <div
      class="flex flex-row justify-between m-0"
      style=" margin-top: -4px;"
    >
      <div
        style="width: 380px; background: #46634F;"
        class=" mr-10 pl-6 pt-10 pb-1 text-white h-full min-w-0"
      >
        <p>
          <strong>Awareness</strong>
        </p>
      </div>
      <div class="font-normal pt-10 ml-10 min-w-0">
        <div class="flex">
          <p style="width: 550px">
            {{ scoreboardContent.awarenessTranslations[mainCandidate.awareness] }}
          </p>
          <p
            style="width: 700px"
            class="ml-10"
          >
            Indicates the overall tendency in estimating the own capacity compared to others. Under-, overestimation,
            close or divergent.
          </p>
        </div>
      </div>
    </div>

    <div class="flex flex-row justify-between m-0">
      <div
        style="width: 380px; background: #46634F; border-radius: 0px 0px 10px 10px; margin-top: -5px;"
        class=" mr-10 pl-6 py-1 text-white min-w-0"
      >
        <p>
          <strong>Performance Under Pressure</strong>
        </p>
      </div>
      <div class="font-normal py-1 ml-10 min-w-0">
        <div class="flex">
          <p style="width: 550px">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="w-14 h-11 flex justify-center"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              stroke-width="2"
              :transform="`rotate(${180 - (mainCandidate.variation - 1) * 45})`"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M5 10l7-7m0 0l7 7m-7-7v18"
              />
            </svg>
          </p>
          <p
            style="width: 700px"
            class="ml-10"
          >
            {{ scoreboardContent.descriptions.variation }}
            The arrow indicates the overall tendency in handling pressure and complexity.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ExecutiveSummary, CognitiveProfileStrengths, Scoreboard, AbilityDescriptions } from '@/content/reportContent.json'

import ShowDetailsBtn from '@/modules/Scoreboard/components/ShowDetailsBtn.vue'
import StateSummary from '@/components/reports/StateSummary.vue'
import { mapGetters } from 'vuex'
import { BarChart } from 'vue-chart-3'
import { gitests as testAppContent } from '@/content/testAppContent.json'

const attentionColor = 'rgb(95, 77, 62)'
const shortTermMemory = 'rgb(77, 83, 97)'
const workingMemory = 'rgb(79, 91, 117)'
const impulseControlColor = 'rgb(31, 32, 37)'
const creativityCognitiveFlexibilityColor = 'rgb(31, 32, 37)'
const conceptualizationColor = 'rgb(180, 156, 121)'
const strategicThinkingColor = 'rgb(180, 156, 121)'

const barColors = [
  attentionColor,
  attentionColor,
  shortTermMemory,
  workingMemory,
  impulseControlColor,
  creativityCognitiveFlexibilityColor,
  conceptualizationColor,
  strategicThinkingColor
]

export default {
  components: {
    ShowDetailsBtn,
    StateSummary,
    BarChart
  },

  props: {
    chartData: { type: Array, required: true },
    pdf: {
      type: Boolean,
      required: true
    },
    anonymized: {
      type: Boolean,
      required: false,
      default: true
    }
  },

  data () {
    return {
      scoreboardContent: Scoreboard,
      AbilityDescriptions,
      CognitiveProfileStrengths,
      sortedData: [],
      candidate: null,
      content: ExecutiveSummary,
      showInformation: false,
      state: 0,
      education: testAppContent.inputs.values.education,
      jobPositions: testAppContent.inputs.values.job_positions,
      strongest: [],
      challenges: [],
      development: [],
      smallBarChartOptions: {
        animation: (this.pdf) ? {
          duration: 0
        } : undefined,
        responsive: false,
        maintainAspectRatio: false,
        barThickness: 7,
        indexAxis: 'y',
        plugins: {
          legend: {
            display: false
          },
          tooltip: {
            enabled: false,
            external: null
          }
        },
        scales: {
          x: {
            reverse: false,
            display: false,
            max: 9,
            grid: {
              display: false,
              drawBorder: false
            }
          },
          y: {
            grid: {
              display: false
            },
            ticks: {
              display: false
            }
          }
        }
      }
    }
  },

  computed: {
    ...mapGetters({
      mainCandidate: 'REPORT/getMainCandidate'
    }),

    data () {
      return {
        labels: this.sortedData.map(el => el.label),
        datasets: [
          {
            label: 'The Candidate',
            data: this.sortedData.map(el => el.stanine),
            backgroundColor: this.sortedData.map(el => el.color)
          }
        ]

      }
    },
    jobLevels () { // parse joblevels from app test content file
      const jobLevelsContent = testAppContent.inputs.values.job_levels
      const jobLevelsParsed = {}
      Object.keys(jobLevelsContent).forEach(groupKey => {
        const levels = jobLevelsContent[groupKey]
        Object.keys(levels).forEach(levelKey => {
          jobLevelsParsed[levelKey] = levels[levelKey]
        })
      })
      return jobLevelsParsed
    }
  },

  created () {
    this.showInformation = !this.anonymized
  },

  mounted () {
    this.sortedData = this.chartData.slice()
    barColors.forEach((el, idx) => {
      this.sortedData[idx].color = el
    })

    this.sortedData.sort((a, b) => parseInt(b.stanine) - parseInt(a.stanine))
    const startStrongest = this.sortedData[0].stanine
    const duplicateStrongest = startStrongest === this.sortedData[1].stanine

    this.sortedData.forEach((measure, index) => {
      if (measure.placeholder) return
      if (startStrongest - measure.stanine <= 1 && !duplicateStrongest) {
        this.strongest.push(index)
      } else if (startStrongest === measure.stanine && duplicateStrongest) {
        this.strongest.push(index)
      } else if (measure.stanine <= 3) {
        this.development.push(index)
      } else if (startStrongest - measure.stanine > 1 && !duplicateStrongest) {
        this.challenges.push(index)
      } else if (startStrongest > measure.stanine && duplicateStrongest) {
        this.challenges.push(index)
      }
    })
  },

  methods: {
    createDataset (result) {
      return {
        labels: [result.label],
        datasets: [
          {
            label: 'The Candidate',
            data: [result.stanine],
            backgroundColor: result.color
          }
        ]
      }
    },

    stateCheck () {
      const state = this.mainCandidate.state
      this.state = state
      let result = 'green'
      if (state === 1) result = 'red'
      else if (state === 2) result = 'yellow'
      else if (state === 3) result = 'green'
      else if (state === 3) result = 'orange'
      else if (state === 3) result = 'orange'
      else result = 'green'
      return result
    },
    stateInfo () {
      const state = this.state
      this.state = state
      let result = 'green'
      if (state === 1) result = 'red'
      else if (state === 2) result = 'yellow'
      else if (state === 3) result = 'green'
      else if (state === 3) result = 'orange'
      else if (state === 3) result = 'orange'
      else result = 'green'
      return result
    },

    stateColorChange (state) {
      if (state >= 3) {
        return 4
      } else {
        return state
      }
    }
  }

}

</script>

<style></style>
